import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import CountdownClock from "react-countdown-clock";
import {Button, LoadingSpinner, Timer} from "../../components";
import {ArrowLeftLongIcon, ArrowRightLongIcon, InfoCircleIcon, PauseIcon, PlayIcon, VolumeOffIcon} from "../../icons";
import {SkipExercisePopup} from "../SkipExercisePopup";
import {ExitTrainingPopup} from "../ExitTrainingPopup";
import {CONFIG} from "../../constants";
import {fetchAccountProfile, fetchDailyExercises, fetchTrainStatus} from "../../redux/apis";
import {useDispatch} from "../../redux/store";
import {ExerciseModel} from "../../resources/models";
import {ExercisesService} from "../../services";

export interface ITrainingModalProps {
  className?: string;
  exercise: ExerciseModel;
  reportWhenFinished?: boolean;
  onClose(result: 'finished' | 'skipped' | 'cancelled'): void;
}

export enum TRAIN_STATUS {
  READY = 'ready',
  PLAYING = 'playing',
  FINISHED = 'finished',
}

export enum TRAIN_SIDE {
  LEFT = 'left',
  RIGHT = 'right',
}

const timerThemes = {
  iplena: {
    bg: '#041D24',
    text: '#FFF',
    arrow: '#97B7BE',
    active: '#23B3D6',
    inactive: '#03617B',
    overlay: '#000000AD',
  },
  mevotec: {
    bg: '#FFF',
    text: '#09344F',
    arrow: '#031521',
    active: '#23B3D6',
    inactive: '#03617B',
    overlay: '#000000AD',
  },
  imed: {
    bg: '#0E0F92',
    text: '#FFF',
    arrow: '#97B7BE',
    active: '#637DFF',
    inactive: '#0F1057',
    overlay: '#000000AD',
  },
  default: {
    bg: '#041D24',
    text: '#FFF',
    arrow: '#97B7BE',
    active: '#23B3D6',
    inactive: '#03617B',
    overlay: '#000000AD',
  },
};

export const TrainingModal: FC<ITrainingModalProps> = ({
  className,
  exercise,
  reportWhenFinished = true,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const videoRef = useRef<HTMLVideoElement>();
  const audioRef = useRef(new Audio('/assets/audios/exercise_music.mp3'));

  const [status, setStatus] = useState(TRAIN_STATUS.READY);
  const [side, setSide] = useState(exercise.getLeftTotalTime() ? TRAIN_SIDE.LEFT : TRAIN_SIDE.RIGHT);
  const [readyTime, setReadyTime] = useState(20);
  const [remainTime, setRemainTime] = useState(exercise.getLeftTotalTime());
  const [leftCount, setLeftCount] = useState(exercise.getLeftRepeatCount());
  const [rightCount, setRightCount] = useState(exercise.isSymmetric() ? 0 : exercise.getRightRepeatCount());
  const [playing, setPlaying] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState<boolean>();
  const [popup, _setPopup] = useState<string>();

  const timerTheme = timerThemes[CONFIG.THEME] || timerThemes.default;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    audioRef.current.play();

    return () => {
      document.body.style.overflow = 'auto';
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.remove();
      }
    };
  }, []);

  const onFinish = () => {
    if (!reportWhenFinished) {
      onClose('finished');
      return;
    }

    ExercisesService.finishTraining(exercise.id, { doneAt: new Date().toISOString() })
      .then(() => {
        dispatch(fetchAccountProfile({ force: true, showSpinner: false, showError: false }));
        dispatch(fetchDailyExercises({ force: true, showSpinner: false, showError: false }));
        dispatch(fetchTrainStatus({ force: true, showSpinner: false, showError: false }));
      })
      .finally(() => {
        onClose('finished');
      });
  };

  const onReadyTimeout = (time) => {
    if (time <= 0) {
      onClickSkipButton();
    } else {
      setReadyTime(time);
    }
  };

  const onTrainTimeout = (time) => {
    setRemainTime(time);
    if (!isPlaying) {
      videoRef.current.pause();
    }
    if (side === TRAIN_SIDE.LEFT) {
      if (time <= 0) {
        if (!exercise.isSymmetric() && exercise.getRightRepeatCount()) {
          setLeftCount(0);
          setSide(TRAIN_SIDE.RIGHT);
          setReadyTime(20);
          setRemainTime(exercise.getRightTotalTime());
          setStatus(TRAIN_STATUS.READY);
        } else {
          setRemainTime(0);
          setPlaying(false);
          setStatus(TRAIN_STATUS.FINISHED);
          onFinish();
        }
        videoRef.current.pause();
        return;
      }
      if (time % exercise.getLeftActionTime() === 0) {
        setLeftCount(time / exercise.getLeftActionTime());
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    } else {
      if (time <= 0) {
        setRemainTime(0);
        setPlaying(false);
        setStatus(TRAIN_STATUS.FINISHED);
        videoRef.current.pause();
        onFinish();
        return;
      }
      if (time % exercise.getRightActionTime() === 0) {
        setRightCount(time / exercise.getRightActionTime());
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    }
  };

  const setPopup = (popup) => {
    _setPopup(popup);
    if (popup) {
      setPlaying(false);
      if (status === TRAIN_STATUS.PLAYING) {
        videoRef.current.pause();
      }
    } else if (videoLoaded) {
      if (status === TRAIN_STATUS.PLAYING) {
        const totalTime = side === TRAIN_SIDE.LEFT ? exercise.getLeftActionTime() : exercise.getRightActionTime();
        videoRef.current.currentTime = remainTime % totalTime;
        videoRef.current.play();
      }
      setPlaying(true);
    }
  };

  const onVideoLoaded = () => {
    setVideoLoaded(true);
    setPlaying(true);
  };

  const exerciseUrl = useMemo(() => exercise.getAnimation().src, [exercise]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.src = exerciseUrl;
      videoRef.current.preload = 'auto';
      videoRef.current.load();
    }
  }, [exerciseUrl]);

  const onAddTime = useCallback((secs) => {
    setReadyTime((prev) => prev + secs);
  }, []);

  const onTogglePause = useCallback(() => {
    setPlaying((prev) => {
      if (prev) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      return !prev;
    });
  }, []);

  const onClickSkipButton = () => {
    if (videoLoaded && status === TRAIN_STATUS.READY) {
      setReadyTime(0);

      let trainSide = side;
      if (trainSide === TRAIN_SIDE.LEFT && leftCount <= 0) {
        trainSide = TRAIN_SIDE.RIGHT;
        setSide(trainSide);
      }
      if (trainSide === TRAIN_SIDE.RIGHT && rightCount <= 0) {
        setPlaying(false);
        onClose('skipped');
        return;
      }

      if (trainSide === TRAIN_SIDE.LEFT) {
        setRemainTime(exercise.getLeftTotalTime());
      } else {
        setRemainTime(exercise.getRightTotalTime());
      }

      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setStatus(TRAIN_STATUS.PLAYING);
    } else {
      setPopup('skip');
    }
  };

  const onSkip = () => {
    onClose('skipped');
  };

  const isPlaying = playing && remainTime > 0 && !popup;

  const modalClass = classNames(
    'training-modal fixed top-0 left-0 w-screen h-screen z-max bg-body1 cursor-default',
    popup && 'hidden',
    className,
  );
  const modalContentClass = classNames(
    'w-full h-full flex-center flex-col bg-training-overlay text-training-text px-6 py-10 transition-all',
  );

  if (videoLoaded === false) {
    return (
      <div className={modalClass}>
        <div className={modalContentClass}>
          <div className="typo-h1">{t('toast.loadingVideoFailed')}</div>
          <Button className="absolute top-10 right-10" variant="text" onClick={() => onClose('cancelled')}>
            <i className="fa fa-times text-2xl" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={modalClass}>
        <div className={modalContentClass}>
          <div className="relative flex-center flex-col">
            <div className="absolute w-72 h-72 bg-primary opacity-10 blur-3xl pointer-events-none" />

            {status === TRAIN_STATUS.READY && (
              <div className="text-training-text font-weight-medium">{t('common.getReady')}</div>
            )}
            {status === TRAIN_STATUS.READY ? (
              <Timer
                className="text-training-text text-8xl font-light mt-2"
                time={readyTime}
                playing={playing && readyTime > 0 && !popup}
                onTick={onReadyTimeout}
              />
            ) : (
              <Timer
                className="text-training-text text-8xl font-light mt-2"
                time={remainTime}
                playing={isPlaying}
                onTick={onTrainTimeout}
              />
            )}
            {status === TRAIN_STATUS.PLAYING && isPlaying && (
              <PauseIcon
                className="text-training-text cursor-pointer mt-2"
                size={32}
                onClick={onTogglePause}
              />
            )}
          </div>
          <div className={classNames('relative flex-center h-[50vh] flex-1 mt-4 mb-2')}>
            <video
              ref={videoRef}
              className={classNames('max-w-full max-h-full rounded-10p', side === TRAIN_SIDE.RIGHT && 'rotate-y-180')}
              playsInline
              onCanPlayThrough={onVideoLoaded}
              onError={() => setVideoLoaded(false)}
            >
              <source src={exercise.getAnimation().src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {!videoLoaded && (
              <LoadingSpinner className="absolute"/>
            )}
          </div>
          <div className="flex items-center flex-col mt-6">
            {videoLoaded && status === TRAIN_STATUS.READY && (
              <>
                {side === TRAIN_SIDE.LEFT && rightCount > 0 && (
                  <div className="typo-h2 !text-training-text cursor-pointer mb-9" onClick={() => setSide(TRAIN_SIDE.RIGHT)}>
                    {t('common.rightSide')} <i className="fa fa-arrow-right ml-2" />
                  </div>
                )}
                {side === TRAIN_SIDE.RIGHT && leftCount > 0 && (
                  <div className="typo-h2 !text-training-text cursor-pointer mb-9" onClick={() => setSide(TRAIN_SIDE.LEFT)}>
                    <i className="fa fa-arrow-left mr-2" /> {t('common.leftSide')}
                  </div>
                )}
                <Button className="!w-84" theme="secondary" onClick={() => onAddTime(5)}>
                  +5S
                </Button>
              </>
            )}
            {status === TRAIN_STATUS.PLAYING && (
              <>
                <div className="typo-h2 !text-training-text">
                  {exercise.getLocalizedContent()?.title || ''}
                </div>
                <div className="flex mt-4">
                  {exercise.getLeftRepeatCount() > 0 && (
                    <div
                      className="relative flex-center rounded-full overflow-hidden mx-3"
                      style={{ background: timerTheme.bg, color: timerTheme.text }}
                    >
                      <CountdownClock
                        key={`${side}-${leftCount}`}
                        seconds={side === TRAIN_SIDE.LEFT ? exercise.getLeftActionTime() : 0}
                        size={100}
                        alpha={0.9}
                        weight={side === TRAIN_SIDE.LEFT ? 2 : 1}
                        fontSize={0}
                        color={side === TRAIN_SIDE.LEFT ? timerTheme.active : timerTheme.inactive}
                        paused={side !== TRAIN_SIDE.LEFT || !isPlaying}
                        showMilliseconds={false}
                      />
                      <div className="absolute flex-center">
                        <ArrowLeftLongIcon className="mr-2" style={{ color: timerTheme.arrow }} />
                        <span className="typo-button">{leftCount}</span>
                      </div>
                      {side !== TRAIN_SIDE.LEFT && (
                        <div className="absolute w-full h-full" style={{ background: timerTheme.overlay }} />
                      )}
                    </div>
                  )}
                  {!exercise.isSymmetric() && exercise.getRightRepeatCount() > 0 && (
                    <div
                      className="relative flex-center rounded-full overflow-hidden mx-3"
                      style={{ background: timerTheme.bg, color: timerTheme.text }}
                    >
                      <CountdownClock
                        key={`${side}-${rightCount}`}
                        seconds={side === TRAIN_SIDE.RIGHT ? exercise.getRightActionTime() : 0}
                        size={100}
                        alpha={0.9}
                        weight={side === TRAIN_SIDE.RIGHT ? 2 : 1}
                        fontSize={0}
                        color={side === TRAIN_SIDE.RIGHT ? timerTheme.active : timerTheme.inactive}
                        paused={side !== TRAIN_SIDE.RIGHT || !isPlaying}
                        showMilliseconds={false}
                      />
                      <div className="absolute flex-center">
                        <span className="typo-button">{rightCount}</span>
                        <ArrowRightLongIcon className="ml-2" style={{ color: timerTheme.arrow }} />
                      </div>
                      {side !== TRAIN_SIDE.RIGHT && (
                        <div className="absolute w-full h-full" style={{ background: timerTheme.overlay }} />
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {status === TRAIN_STATUS.PLAYING && !playing && (
            <div className="absolute top-0 left-0 z-[100] w-full h-full flex-center bg-overlay1">
              <Button className="!w-12.5 !rounded-full !p-0" theme="secondary" link={`/exercises/${exercise.id}/learn`}>
                <InfoCircleIcon size={24} />
              </Button>
              <Button className="!w-25 !h-25 !rounded-full !p-0 mx-6" theme="primary" onClick={onTogglePause}>
                <PlayIcon size={40} />
              </Button>
              <Button className="!w-12.5 !rounded-full !p-0" theme="secondary">
                <VolumeOffIcon size={24} />
              </Button>
            </div>
          )}
        </div>

        <Button className="absolute top-4 sm:top-10 left-4 sm:left-16" variant="text" onClick={() => setPopup('cancel')}>
          <i className="fa fa-arrow-left text-xs mr-3" /> {t('common.cancel')}
        </Button>

        {videoLoaded && (
          <Button className="absolute top-4 sm:top-10 right-4 sm:right-16" variant="text" onClick={onClickSkipButton}>
            {t('common.skip')} <i className="fa fa-arrow-right text-xs ml-3" />
          </Button>
        )}
      </div>

      {popup === 'skip' && (
        <SkipExercisePopup
          exercise={exercise}
          onSkip={onSkip}
          onClose={() => setPopup(undefined)}
        />
      )}

      {popup === 'cancel' && (
        <ExitTrainingPopup
          exercise={exercise}
          onExit={() => onClose('cancelled')}
          onClose={() => setPopup(undefined)}
        />
      )}
    </>
  );
};
