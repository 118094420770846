import {IPhoto} from "../../resources/interfaces";
import {ScanModel} from "../../resources/models";
import {PHOTO_TYPE} from "../../resources/enums";

export const cropImageFromGeometry = async (src, rect, scaleX, scaleY): Promise<IPhoto> => {
  if (!src || !rect) {
    return null;
  }

  const canvas = document.createElement('canvas');
  canvas.width = rect.width;
  canvas.height = rect.height;

  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    src,
    rect.x * scaleX,
    rect.y * scaleY,
    rect.width * scaleX,
    rect.height * scaleY,
    0,
    0,
    rect.width,
    rect.height,
  );

  const blob: Blob = await new Promise((resolve) => {
    canvas.toBlob(resolve, 'image/jpeg');
  });
  const dataURL = canvas.toDataURL('image/jpeg');

  canvas.remove();

  return { blob, dataURL };
};

export const getDataUrlFromBlob = (blob: Blob) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const dataURL = reader.result;
      resolve(dataURL as string);
    };

    reader.readAsDataURL(blob);
  });
};

export const getAnalyzedPhoto = async (scan: ScanModel, type: PHOTO_TYPE) => {
  const src = scan[type]?.src;
  if (!src || !scan.points) {
    return undefined;
  }

  const img = document.createElement('img');
  try {
    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
      img.src = src;
    });
  } catch {
    return undefined;
  }

  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);

  ctx.fillStyle = '#0080C9';
  ctx.strokeStyle = '#0080C9';
  ctx.lineWidth = img.width / 150;

  const pointGroups = scan.getPointGroups(type);
  pointGroups.forEach((group) => {
    drawPointGroup(ctx, group);
  });

  if (type === PHOTO_TYPE.SIDE && scan.points.line) {
    scan.points.line.forEach((point) => {
      const r = ctx.lineWidth;
      ctx.beginPath();
      ctx.ellipse(point[0], point[1], r, r, 0, 0, 2 * Math.PI);
      ctx.fill();
    });
  }

  return canvas;
};

export const drawPointGroup = (ctx: CanvasRenderingContext2D, points: number[][]) => {
  if (points.length === 1) {
    drawPoint(ctx, points[0]);
  } else if (points.length === 4) {
    drawLine(ctx, [points[0], points[2], points[1], points[3], points[0]]);
  } else {
    drawLine(ctx, points);
  }
};

export const drawLine = (ctx: CanvasRenderingContext2D, points: number[][]) => {
  points = points.filter((point) => point?.length >= 2);
  ctx.beginPath();
  ctx.moveTo(points[0][0], points[0][1]);
  points.slice(1).forEach((point) => {
    ctx.lineTo(point[0], point[1]);
  });
  ctx.stroke();

  points.forEach((point) => {
    drawPoint(ctx, point);
  });
};

export const drawPoint = (ctx: CanvasRenderingContext2D, point: number[]) => {
  if (point?.length >= 2) {
    const r = ctx.lineWidth * 2;
    ctx.beginPath();
    ctx.ellipse(point[0], point[1], r, r, 0, 0, 2 * Math.PI);
    ctx.fill();
  }
};
