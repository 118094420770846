import React, {FC} from "react";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {Button, Popup} from "../../components";

export interface IMarketPopupProps {
  className?: string;
  onClose(): void;
}

export const MarketPopup: FC<IMarketPopupProps> = ({
  className,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Popup
      contentClass={classNames('text-typo1', className)}
      width="32rem"
      onClose={onClose}
    >
      <h1 className="text-center">{t('market.title')}</h1>

      <div className="flex flex-col items-center mt-8 gap-6">
        <img className="max-w-55" src="/assets/images/market/book.png" alt=""/>
        <div className="flex w-full flex-col gap-3">
          <h2 className="line-clamp-2 font-bold">
            Das Schmerzfrei-Geheimnis: Schmerzen ganzheitlich verstehen und für immer loswerden | Das 3-Minuten-Programm
            für den Alltag
          </h2>
          <div className="text-input-placeholder">Alexander Srokovskyi</div>
          <div className="typo-h1 text-center font-bold lowercase">{t('market.from')} 10.95 €</div>
        </div>
        <a className="w-full" href="https://amzn.eu/d/0dwPdPkh" target="_blank" rel="noreferrer">
          <Button fullWidth>{t('market.buy')}</Button>
        </a>
      </div>
    </Popup>
  );
};
