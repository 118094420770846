import React, { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactDropzone from 'react-dropzone';
import { Button } from '../Button';
import { CameraTimer } from '../CameraTimer';
import { Timer } from '../Timer';
import { IWebcamRef, Webcam } from '../Webcam';
import { PhotoCropPopup } from '../PhotoCropPopup';
import { ImageIcon, InfoCircleIcon, ShootIcon } from '../../icons';
import { useWindowSize } from '../../hooks';
import { IPhoto } from '../../resources/interfaces';
import { cropImageFromGeometry } from '../../utils/helpers';

export interface IPhotoScannerProps {
  className?: string;
  header?: ReactNode;
  aspectRatio?: number;
  showWireframe?: boolean;
  onTakePhoto?: (photo: IPhoto) => void;
}

export const PhotoScanner: FC<IPhotoScannerProps> = ({
  className,
  header,
  aspectRatio = 3 / 4,
  showWireframe = true,
  onTakePhoto,
}) => {
  const { t } = useTranslation();

  const { windowWidth } = useWindowSize();
  const ref = useRef<HTMLDivElement>();
  const camRef = useRef<IWebcamRef>();
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [error, setError] = useState<string>();
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [maskRect, setMaskRect] = useState({ left: 0, top: 0, right: 0, bottom: 0 });
  const [timer, setTimer] = useState<number>();
  const [timerPlaying, setTimerPlaying] = useState(false);
  const [photo, setPhoto] = useState<IPhoto>();
  const [croppingImage, setCroppingImage] = useState<IPhoto>();

  const isMiniMode = useMemo(() => size.height < 1000, [size]);

  const horizontalMode = useMemo(
    () => windowWidth < 1024 && size.width >= size.height * aspectRatio + 70,
    [windowWidth, size, aspectRatio],
  );

  const onInitCamera = useCallback((ref) => {
    camRef.current = ref;
    setCameraEnabled(true);
  }, []);

  const onResize = React.useCallback(() => {
    const el = ref.current;
    if (el) {
      const width = el.clientWidth;
      const height = el.clientHeight;

      let w = width - 50;
      let h = width / aspectRatio;
      const maxH = horizontalMode ? height - 80 : isMiniMode ? height - 150 : height - 250;
      if (h > maxH) {
        h = maxH;
        w = h * aspectRatio;
      }

      const left = Math.round((width - w) / 2) - (horizontalMode ? 35 : 0);
      const top = Math.round((height - h) / 2) + (horizontalMode ? 25 : -10);

      setSize({ width, height });
      setMaskRect({
        left,
        right: left + w,
        top,
        bottom: top + h,
      });
    }
  }, [aspectRatio, horizontalMode, isMiniMode, windowWidth]);

  React.useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  const maskSvg = useMemo(() => {
    if (!size.width || !size.height) {
      return null;
    }

    const l = maskRect.left / size.width;
    const r = maskRect.right / size.width;
    const t = maskRect.top / size.height;
    const b = maskRect.bottom / size.height;
    const rx = 10 / size.width;
    const ry = 10 / size.height;
    return (
      <svg className="absolute">
        <defs>
          <clipPath id="mask-path" clipPathUnits="objectBoundingBox">
            <path
              d={`M0,0 L0,1 L1,1 L1,0 L0,0
              L${l + rx},${t} L${r - rx},${t} C${r - rx},${t} ${r},${t} ${r}${t + ry}
              L${r},${t + ry} L${r},${b - ry} C${r},${b - ry} ${r},${b} ${r - rx}${b}
              L${r - rx},${b} L${l + rx},${b} C${l + rx},${b} ${l},${b} ${l}${b - ry}
              L${l}${b - ry} L${l},${t + ry} C${l},${t + ry} ${l},${t} ${l + rx}${t}
              Z`}
            />
          </clipPath>
        </defs>
      </svg>
    );
  }, [size, maskRect]);

  const onShot = () => {
    if (timer) {
      setTimerPlaying(true);
    } else {
      onTakeCroppedPhoto();
    }
  };

  const onTimeout = () => {
    setTimerPlaying(false);
    onTakeCroppedPhoto();
  };

  const onTakeCroppedPhoto = () => {
    const videoWidth = camRef.current.video.clientWidth;
    const videoHeight = camRef.current.video.clientHeight;
    const rect = {
      x: maskRect.left - (size.width - videoWidth) / 2,
      y: maskRect.top - (size.height - videoHeight) / 2,
      width: maskRect.right - maskRect.left,
      height: maskRect.bottom - maskRect.top,
    };
    cropImageFromGeometry(camRef.current.video, rect, 1, 1).then((photo) => {
      setPhoto(photo);
    });
  };

  const onUsePhoto = () => {
    if (onTakePhoto) {
      onTakePhoto(photo);
    }
  };

  const onSelectFile = (files: File[]) => {
    if (files.length) {
      setCroppingImage({
        blob: files[0],
        dataURL: URL.createObjectURL(files[0]),
      });
    }
  };

  return (
    <div ref={ref} className={classNames('flex-center relative', className)}>
      {!photo && (
        <>
          <Webcam className="h-full w-full" onInit={onInitCamera} onError={setError} />

          {maskSvg}
          <div
            className="absolute left-0 top-0 h-full w-full bg-black/40"
            style={{
              clipPath: 'url(#mask-path)',
              clipRule: 'evenodd',
              fillRule: 'evenodd',
            }}
          />

          {showWireframe && (
            <div
              className="flex-center absolute"
              style={{
                top: maskRect.top + (isMiniMode ? 10 : 20),
                left: maskRect.left + (isMiniMode ? 10 : 20),
                width: `${maskRect.right - maskRect.left - (isMiniMode ? 20 : 40)}px`,
                height: `${maskRect.bottom - maskRect.top - (isMiniMode ? 20 : 40)}px`,
              }}
            >
              <div
                className={classNames(
                  'absolute left-0 top-0 border-l-4 border-t-4 border-primary',
                  isMiniMode ? 'h-6 w-6' : 'h-8 w-8',
                )}
              />
              <div
                className={classNames(
                  'absolute right-0 top-0 border-r-4 border-t-4 border-primary',
                  isMiniMode ? 'h-6 w-6' : 'h-8 w-8',
                )}
              />
              <div
                className={classNames(
                  'absolute bottom-0 left-0 border-b-4 border-l-4 border-primary',
                  isMiniMode ? 'h-6 w-6' : 'h-8 w-8',
                )}
              />
              <div
                className={classNames(
                  'absolute bottom-0 right-0 border-b-4 border-r-4 border-primary',
                  isMiniMode ? 'h-6 w-6' : 'h-8 w-8',
                )}
              />
              <div className={classNames('absolute w-1 bg-primary', isMiniMode ? '-top-2 h-10' : '-top-4 h-16')} />
              <div
                className={classNames('absolute w-1 bg-primary', isMiniMode ? '-bottom-2 h-10' : '-bottom-4 h-16')}
              />
              <div className={classNames('absolute h-1 bg-primary', isMiniMode ? '-left-2 w-10' : '-left-4 w-16')} />
              <div className={classNames('absolute h-1 bg-primary', isMiniMode ? '-right-2 w-10' : '-right-4 w-16')} />
              <div
                className={classNames(
                  'absolute rounded-full border-4 border-primary',
                  isMiniMode ? 'h-6 w-6' : 'h-8 w-8',
                )}
              />
            </div>
          )}
        </>
      )}

      {error && <div className="card1 absolute px-5 py-4">{error}</div>}

      {cameraEnabled && (
        <>
          <div
            className={classNames(
              'absolute left-0 flex w-full items-center',
              photo ? 'text-primary' : 'text-white',
              isMiniMode ? 'top-4 px-6' : 'top-8 px-10',
            )}
          >
            {header}
            <CameraTimer
              className={classNames('ml-auto', photo && 'pointer-events-none opacity-0')}
              value={timer}
              options={[5, 10]}
              onChanges={setTimer}
            />
          </div>

          {photo ? (
            <div className="flex-center h-full w-full flex-col px-10 pb-10 pt-28">
              <div className="flex-center my-auto h-[60vh] flex-1">
                <img className="max-h-full max-w-full rounded-lg" src={photo.dataURL} alt="" />
              </div>
              <div className="mt-10 flex items-center gap-6">
                <Button className="!w-43" theme="primary" variant="outlined" onClick={() => setPhoto(undefined)}>
                  <i className="fa fa-times mr-2 text-2xl" /> {t('common.retake')}
                </Button>
                <Button className="!w-43" theme="primary" onClick={onUsePhoto}>
                  <i className="fa fa-check mr-2 text-2xl" /> {t('common.usePhoto')}
                </Button>
              </div>
            </div>
          ) : (
            <>
              {timer && (
                <div className="absolute">
                  <Timer
                    className="text-64p font-semibold text-white"
                    time={timer}
                    playing={timerPlaying}
                    format="s"
                    onFinished={onTimeout}
                  />
                </div>
              )}

              <div
                className={classNames(
                  'flex-center absolute gap-8 lg:gap-12',
                  horizontalMode ? 'right-4 flex-col md:right-8' : isMiniMode ? 'bottom-4' : 'bottom-10',
                )}
              >
                <ReactDropzone
                  accept={{
                    'image/jpeg': ['.jpeg', '.png'],
                  }}
                  onDrop={onSelectFile}
                >
                  {(state) => (
                    <div {...state.getRootProps()}>
                      <input {...state.getInputProps()} />
                      <div className="flex-center h-12 w-12 cursor-pointer rounded-full bg-overlay1 text-white transition-all hover:scale-110">
                        <ImageIcon size={24} />
                      </div>
                    </div>
                  )}
                </ReactDropzone>
                <ShootIcon
                  className="cursor-pointer text-white transition-all hover:scale-110"
                  size={isMiniMode ? 60 : 70}
                  onClick={onShot}
                />
                <Link
                  className="flex-center pointer-events-none h-12 w-12 cursor-pointer rounded-full bg-overlay1 text-white opacity-0 transition-all hover:scale-110"
                  to="/scanner/photo-tips"
                >
                  <InfoCircleIcon size={24} />
                </Link>
              </div>
            </>
          )}
        </>
      )}

      {croppingImage && (
        <PhotoCropPopup
          photo={croppingImage}
          aspectRatio={aspectRatio}
          onCrop={setPhoto}
          onClose={() => setCroppingImage(undefined)}
        />
      )}
    </div>
  );
};
